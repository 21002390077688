<template>
  <v-container>
    <div class="ml-2">
      <PageTitle class="ft font-size-md" text="Stock Management (Lubes)" />
    </div>
    <div class="buttonText ml-1" id="result">
      <v-chip
        label
        color="success"
        class="ml-2 mt-3 white--text font-weight-bold ft"
      >
        List Of Available Stocks For
        <span class="text-uppercase  ml-2">{{
          date | timestamp("MMMM, YYYY")
        }}</span>
      </v-chip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            @click.stop="
              $store.dispatch('stocks/lubeItems', {
                page: paginate.page,
                limit: paginate.limit
              })
            "
            fab
            icon
            small
            v-on="on"
            class="mt-2 ml-3"
          >
            <i
              class="material-icons-outlined font-size-md primary--text font-weight-bold"
              >refresh</i
            >
          </v-btn>
        </template>
        <span class=" ft font-weight-bold ">Refresh </span>
      </v-tooltip>
    </div>
    <v-row align="center">
      <v-col cols="12" sm="12">
        <v-chip-group light v-model="tag" multiple show-arrows>
          <SkeletonPreloader
            v-if="isBranchesLoading"
            :count="12"
            class="ml-2"
          />
          <v-chip
            v-else
            label
            active-class="primary--text"
            color="white"
            class="box-shadow-light ft"
            v-for="(tag, index) in tags"
            :value="tag.id"
            :key="index"
          >
            <span class="font-weight-bold text-uppercase ">{{ tag.name }}</span>
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="lubes"
              item-key="product"
              :server-items-length="paginate.total"
              loading-text="Loading... Please wait"
              :loading="isListLoading || pageLoading"
              :sort-by="['product']"
              :options.sync="options"
              :footer-props="{
                itemsPerPageOptions: [5, 20, 30]
              }"
              class="box-shadow-light ft font-size-md"
            >
              <template #item.avatar="{item}">
                <v-avatar
                  v-if="item.productId"
                  color="primary"
                  style="margin-right: -80px"
                  size="35"
                >
                  <span class="white--text subtitle-1 text-uppercase">{{
                    item.productId.name.charAt(0)
                  }}</span>
                </v-avatar>
              </template>
              <template #item.branchId="{item}">
                <v-chip
                  label
                  color="primary"
                  dense
                  v-if="item.branchId"
                  class=" ft font-weight-medium font-size-sm"
                  >{{ item.branchId.name }}</v-chip
                >
              </template>
              <template #item.product="{item}">
                <v-chip label small color="primary">
                  <span v-if="item.productId">{{ item.productId.name }}</span>
                </v-chip>
              </template>
              <template #item.minimumThreshold="{item}">
                <v-chip
                  :color="
                    Number(item.currentStockQuantity) >= item.minimumThreshold
                      ? 'success'
                      : 'warning'
                  "
                  label
                  small
                >
                  <span class="font-weight-bold">{{
                    item.minimumThreshold
                  }}</span>
                </v-chip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col>
    </v-row>

    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import SnackBarMixins from "../../../mixins/SnackBarMixins";
import { timestamp } from "../../../filters/timestamp";
import { mapGetters } from "vuex";
export default {
  name: "Index",
  components: {
    PageTitle,
    SnackBar: () => import(`../../../components/SnackBar`),
    SkeletonPreloader: () => import(`../../../components/SkeletonPreloader`)
  },
  data() {
    return {
      stocks: [],
      tags: [],
      tag: [],
      date: new Date(),
      options: {},
      headers: [
        {
          text: "",
          value: "avatar"
        },
        {
          text: "Branch",
          value: "branchId"
        },
        {
          text: "Product",
          value: "product"
        },
        {
          text: "Quantity",
          value: "currentStockQuantity"
        },

        {
          text: "Minimum Threshold",
          value: "minimumThreshold",
          align: "center"
        }
      ]
    };
  },
  filters: {
    timestamp
  },
  mixins: [SnackBarMixins],
  computed: {
    ...mapGetters({
      isBranchesLoading: "getIsListLoading",
      isListLoading: "getIsListLoading",
      pageLoading: "getIsPageLoading",
      paginate: "stocks/getPagination",
      branches: "branches/getAllBranches",
      lubes: "stocks/getAllLubeStock"
    })
  },
  watch: {
    branches(payload) {
      this.tags = payload.map(branch => {
        return {
          name: branch.name,
          id: branch.id,
          slug: branch.slug
        };
      });
    },
    tag(value) {
      if (value.length > 0) {
        this.$store.dispatch("stocks/lubeItemsByBranch", {
          page: this.paginate.page,
          limit: this.paginate.limit,
          branches: value
        });
      } else {
        this.$store.dispatch("stocks/lubeItems", {
          isPaginated: true,
          page: this.paginate.page,
          limit: this.paginate.limit
        });
      }
    },
    options(paginate) {
      if (this.tag.length > 0) {
        this.$store.dispatch("stocks/lubeItemsByBranch", {
          page: paginate.page,
          limit: paginate.itemsPerPage,
          branches: this.tag
        });
      } else {
        this.$store.dispatch("stocks/lubeItems", {
          isPaginated: true,
          page: paginate.page,
          limit: paginate.itemsPerPage
        });
      }
    }
  },
  methods: {},
  async created() {
    this.$store.dispatch("branches/list");
    this.$store.dispatch("stocks/lubeItems", {
      isPaginated: true,
      page: this.paginate.page,
      limit: this.paginate.limit
    });
  }
};
</script>

<style scoped></style>
