var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"ft font-size-md",attrs:{"text":"Stock Management (Lubes)"}})],1),_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('v-chip',{staticClass:"ml-2 mt-3 white--text font-weight-bold ft",attrs:{"label":"","color":"success"}},[_vm._v(" List Of Available Stocks For "),_c('span',{staticClass:"text-uppercase  ml-2"},[_vm._v(_vm._s(_vm._f("timestamp")(_vm.date,"MMMM, YYYY")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2 ml-3",attrs:{"fab":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('stocks/lubeItems', {
              page: _vm.paginate.page,
              limit: _vm.paginate.limit
            })}}},on),[_c('i',{staticClass:"material-icons-outlined font-size-md primary--text font-weight-bold"},[_vm._v("refresh")])])]}}])},[_c('span',{staticClass:" ft font-weight-bold "},[_vm._v("Refresh ")])])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-chip-group',{attrs:{"light":"","multiple":"","show-arrows":""},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}},[(_vm.isBranchesLoading)?_c('SkeletonPreloader',{staticClass:"ml-2",attrs:{"count":12}}):_vm._l((_vm.tags),function(tag,index){return _c('v-chip',{key:index,staticClass:"box-shadow-light ft",attrs:{"label":"","active-class":"primary--text","color":"white","value":tag.id}},[_c('span',{staticClass:"font-weight-bold text-uppercase "},[_vm._v(_vm._s(tag.name))])])})],2)],1)],1),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:"box-shadow-light ft font-size-md",attrs:{"headers":_vm.headers,"items":_vm.lubes,"item-key":"product","server-items-length":_vm.paginate.total,"loading-text":"Loading... Please wait","loading":_vm.isListLoading || _vm.pageLoading,"sort-by":['product'],"options":_vm.options,"footer-props":{
              itemsPerPageOptions: [5, 20, 30]
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [(item.productId)?_c('v-avatar',{staticStyle:{"margin-right":"-80px"},attrs:{"color":"primary","size":"35"}},[_c('span',{staticClass:"white--text subtitle-1 text-uppercase"},[_vm._v(_vm._s(item.productId.name.charAt(0)))])]):_vm._e()]}},{key:"item.branchId",fn:function(ref){
            var item = ref.item;
return [(item.branchId)?_c('v-chip',{staticClass:" ft font-weight-medium font-size-sm",attrs:{"label":"","color":"primary","dense":""}},[_vm._v(_vm._s(item.branchId.name))]):_vm._e()]}},{key:"item.product",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[(item.productId)?_c('span',[_vm._v(_vm._s(item.productId.name))]):_vm._e()])]}},{key:"item.minimumThreshold",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":Number(item.currentStockQuantity) >= item.minimumThreshold
                    ? 'success'
                    : 'warning',"label":"","small":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.minimumThreshold))])])]}}])})],1)]],2)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }